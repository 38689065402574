import {validationMixin} from "vuelidate/src";
import { TheMask } from 'vue-the-mask'
import { VMoney } from 'v-money'
const { required, minLength, email } =  require('vuelidate/lib/validators');
const axios = require('axios');

export default {
    name: 'contact',
    mixins: [validationMixin],
    components: { TheMask },
    directives: { money: VMoney },
    data() {
        return {
            money: {
                decimal: '.',
                thousands: ',',
                prefix: '$',
                suffix: '',
                precision: 2
            },
            user: {
                name: '',
                email: '',
                phone: '',
                searching: '',
                type: '',
                place: '',
                budget: '',
                message: ''
            },
            loading: false,
            error: false
        }
    },
    validations: {
        user: {
            name: { required },
            email: { required, email },
            phone: { required },
            budget: { required },
            searching: { required },
            place: {required},
            message: {  }
        }
    },
    methods: {
        submit: async function () {
            this.loading = true;

            let data = {};
            data.name = this.user.name;
            data.email = this.user.email;
            data.phone = this.user.phone;
            data.message = `${this.user.searching} | ${this.user.type} | ${this.user.place} | ${this.user.budget} | ${this.user.message}`;
            data.source = process.env.VUE_APP_SOURCE;
            data.public_key = process.env.VUE_APP_PUBLIC_KEY;

            let url = `${process.env.VUE_APP_API_URL}/api/index/contact`;
            let response = await axios({ headers: {'Accept-Language': 'es_MX'}, method: 'POST', url: url, data });
            if(response.status === 200) await this.$router.push('/thanks');
            else {
                this.loading = false;
                this.error = true;
            }
        }
    }
}
